import React from "react";
import counterpart from "counterpart";

import getTranslationOrNull from "@utility/translation-util";
import LoginForm from "./login-form";
import LoginWrapper from "./login-wrapper";
import ExternalLogin from "./external-login";

class Login extends React.Component {
    render() {
        const displayForm = shiftContext.authenticationModes.filter((cfg) => cfg.mode === "Form").length > 0;

        const description = getTranslationOrNull("account.login_description");

        return (
            <LoginWrapper capContainerWidth={Boolean(description)}>
                <h3 className="semi-bold">{counterpart("account.welcome")}</h3>
                {displayForm && <LoginForm referrer={this.props.referrer} />}
                {description && <h4>{description}</h4>}
                <ExternalLogin referrer={this.props.referrer} />
            </LoginWrapper>
        );
    }
}

Login.displayName = "Login";

export default Login;
