import * as d3 from "d3";
import _ from "underscore";

const NUM_NODES = 100;
const SIZE = 900;

const NETWORK_MIN = 3;
const NETWORK_MAX = 6;

// Generate a bunch of nodes
const initialData = d3.range(0, NUM_NODES).map((index) => ({
    id: index,
}));

let nodes;
const links = [];
let link;
let node;
let simulation;
let vis;

window.shiftDrawGraph = function (status) {
    const visNodes = vis.selectAll(".node").data(nodes).enter().append("circle").attr("class", "node").attr("r", 5);

    node = node ? node.merge(visNodes) : visNodes;

    if (status === "success") {
        const visEdges = vis.selectAll(".link").data(links).enter().append("line").attr("class", "link");
        link = link ? link.merge(visEdges) : visEdges;
        simulation.force("link", d3.forceLink(links)).alpha(2).restart();
    }
};

const connectNodes = function () {
    let networkSize = 0;
    for (let index = 0; index < nodes.length - 1; index++) {
        if (networkSize === 0) {
            networkSize = _.random(NETWORK_MIN, NETWORK_MAX);
        }

        let targetIndex = _.random(index, index + networkSize);

        if (targetIndex > nodes.length - 1) {
            targetIndex = _.random(index, nodes.length - 1);
        }
        links.push({
            source: nodes[index],
            target: targetIndex,
        });

        networkSize--;
    }
};

window.shiftInitializeGraph = () => {
    vis = d3.select(".login-animation").append("svg:svg").attr("width", "100%").attr("height", "100%");

    simulation = d3
        .forceSimulation(initialData)
        .force("center", d3.forceCenter(SIZE / 2, SIZE / 2))
        .force("charge", d3.forceManyBody().strength(-12));

    nodes = simulation.nodes();

    connectNodes();

    window.shiftInitializeGraph = () => {};

    window.shiftDrawGraph();

    simulation.on("tick", () => {
        if (link) {
            link.attr("x1", (targetNode) => targetNode.source.x)
                .attr("y1", (targetNode) => targetNode.source.y)
                .attr("x2", (targetNode) => targetNode.target.x)
                .attr("y2", (targetNode) => targetNode.target.y);
        }

        node.attr("cx", (targetNode) => targetNode.x).attr("cy", (targetNode) => targetNode.y);
    });
};
