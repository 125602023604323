import React from "react";

import { logos as logoContext } from "@app/utility/require/context/logo";
import classNames from "classnames";
import PropTypes from "prop-types";

class LoginWrapper extends React.PureComponent {
    componentDidMount() {
        window.shiftInitializeGraph();
    }

    render() {
        return (
            <div className="login">
                <div className="logos">
                    <img src={logoContext("V")} alt="shift-logo" />
                </div>
                <div className="login-animation" />
                <div
                    className={classNames({
                        "login-container": true,
                        "login-container--capped-width": this.props.capContainerWidth,
                    })}
                >
                    {this.props.children}
                </div>
            </div>
        );
    }
}

LoginWrapper.displayName = "LoginWrapper";

LoginWrapper.propTypes = {
    capContainerWidth: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default LoginWrapper;
