import counterpart from "counterpart";

const getTranslationOrNull = (...args) => {
    let translationFound = true;
    const cbTranslationNotFound = () => {
        translationFound = false;
    };

    counterpart.addTranslationNotFoundListener(cbTranslationNotFound);
    const translationResult = counterpart(...args);
    counterpart.removeTranslationNotFoundListener(cbTranslationNotFound);

    return translationFound ? translationResult : null;
};

export default getTranslationOrNull;
