import "promise-shim";

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";

import { registerLanguages } from "@app/languages";
import "@app/login-animation";
import { DesignSystemProvider } from "@shift-technology/shift-design-system";
import SecurityWrapper from "./security-wrapper";

import "~/less/login.less";
import { loadClientTheme } from "@app/theme";
loadClientTheme();

registerLanguages();

const hash = window.location.hash;
let referrer = "/";

if (hash !== "" && hash.indexOf("login") === -1 && hash.indexOf("resetPassword") === -1) {
    referrer += hash;
}

ReactDOM.render(
    <Router>
        <DesignSystemProvider>
            <SecurityWrapper referrer={referrer} />
        </DesignSystemProvider>
    </Router>,
    document.getElementById("app-container")
);
