const updateIds = (elements, current = "") =>
    elements.forEach((item) => {
        item.id = `${current}${item.tag}`;
        if (item.type === "section") {
            updateIds(item.items, `${item.id}/`);
        }
    });

const helpElements = [
    {
        tag: "user-guide",
        type: "section",
        title: "help_page.p&c.title1",
        items: [
            {
                tag: "connection",
                type: "section",
                title: "help_page.p&c.text1",
                items: [
                    { type: "text", value: "help_page.p&c.text2" },
                    { type: "img", value: "i1" },
                    { type: "text", value: "help_page.p&c.text3" },
                    { type: "text", value: "help_page.p&c.text4" },
                    { type: "text", value: "help_page.p&c.text5" },
                    { type: "text", value: "help_page.p&c.text6" },
                    { type: "text", value: "help_page.p&c.text7" },
                    { type: "text", value: "help_page.p&c.text8" },
                    { type: "text", value: "help_page.p&c.text9" },
                ],
            },
            {
                tag: "account-parameters",
                type: "section",
                title: "help_page.p&c.text10",
                items: [
                    { type: "text", value: "help_page.p&c.text11" },
                    { type: "img", value: "i2" },
                    { type: "text", value: "help_page.p&c.text12" },
                ],
            },
            {
                tag: "claims-page",
                type: "section",
                title: "help_page.p&c.text13",
                items: [
                    {
                        tag: "presentation",
                        type: "section",
                        title: "help_page.p&c.text14",
                        items: [
                            { type: "text", value: "help_page.p&c.text15" },
                            { type: "img", value: "i3" },
                            { type: "text", value: "help_page.p&c.text16" },
                            { type: "text", value: "help_page.p&c.text17" },
                        ],
                    },
                    {
                        tag: "columns",
                        type: "section",
                        title: "help_page.p&c.text18",
                        items: [
                            { type: "text", value: "help_page.p&c.text19" },
                            { type: "text", value: "help_page.p&c.text20" },
                            { type: "img", value: "i4" },
                        ],
                    },
                    {
                        tag: "filters",
                        type: "section",
                        title: "help_page.p&c.text21",
                        items: [
                            { type: "text", value: "help_page.p&c.text22" },
                            { type: "text", value: "help_page.p&c.text23" },
                            { type: "img", value: "i5" },
                            { type: "text", value: "help_page.p&c.text24" },
                            { type: "text", value: "help_page.p&c.text25" },
                            { type: "text", value: "help_page.p&c.text26" },
                            { type: "text", value: "help_page.p&c.text27" },
                            { type: "img", value: "i6" },
                            { type: "text", value: "help_page.p&c.text28" },
                        ],
                    },
                ],
            },
            {
                tag: "claim-page",
                type: "section",
                title: "help_page.p&c.text29",
                items: [
                    { type: "text", value: "help_page.p&c.text30" },
                    { type: "img", value: "i7" },
                    { type: "text", value: "help_page.p&c.text31" },
                    { type: "img", value: "i8" },
                    { type: "text", value: "help_page.p&c.text32" },
                    { type: "img", value: "i9" },
                    { type: "text", value: "help_page.p&c.text33" },
                    { type: "img", value: "i10" },
                    { type: "text", value: "help_page.p&c.text34" },
                    { type: "img", value: "i11" },
                    { type: "text", value: "help_page.p&c.text35" },
                    { type: "text", value: "help_page.p&c.text36" },
                    { type: "img", value: "i12" },
                    { type: "text", value: "help_page.p&c.text37" },
                    { type: "text", value: "help_page.p&c.text38" },
                ],
            },
        ],
    },
    {
        tag: "frequent-questions",
        type: "section",
        title: "help_page.p&c.title2",
        items: [
            {
                tag: "forgot-password",
                type: "section",
                title: "help_page.p&c.text39",
                items: [
                    { type: "text", value: "help_page.p&c.text40" },
                    { type: "text", value: "help_page.p&c.text41" },
                    { type: "text", value: "help_page.p&c.text42" },
                    { type: "text", value: "help_page.p&c.text43" },
                    { type: "text", value: "help_page.p&c.text44" },
                ],
            },
            {
                tag: "no-claims",
                type: "section",
                title: "help_page.p&c.text45",
                items: [
                    { type: "text", value: "help_page.p&c.text46" },
                    { type: "img", value: "i20" },
                ],
            },
            {
                tag: "loading-forever",
                type: "section",
                title: "help_page.p&c.text47",
                items: [{ type: "text", value: "help_page.p&c.text48" }],
            },
        ],
    },
];

updateIds(helpElements);

export default helpElements;
