const updateIds = (elements, current = "") =>
    elements.forEach((item) => {
        item.id = `${current}${item.tag}`;
        if (item.type === "section") {
            updateIds(item.items, `${item.id}/`);
        }
    });

const helpElements = [
    {
        tag: "user-guide",
        type: "section",
        title: "help_page.health.title1",
        items: [
            {
                tag: "connection",
                type: "section",
                title: "help_page.health.text1",
                items: [
                    { type: "text", value: "help_page.health.text2" },
                    { type: "img", value: "i1" },
                    { type: "text", value: "help_page.health.text3" },
                    { type: "text", value: "help_page.health.text4" },
                    { type: "text", value: "help_page.health.text5" },
                    { type: "text", value: "help_page.health.text6" },
                    { type: "text", value: "help_page.health.text7" },
                    { type: "text", value: "help_page.health.text8" },
                    { type: "text", value: "help_page.health.text9" },
                ],
            },
            {
                tag: "account-parameters",
                type: "section",
                title: "help_page.health.text10",
                items: [
                    { type: "text", value: "help_page.health.text11" },
                    { type: "img", value: "i2" },
                    { type: "text", value: "help_page.health.text12" },
                    { type: "img", value: "i3" },
                ],
            },
            {
                tag: "claims-page",
                type: "section",
                title: "help_page.health.text13",
                items: [
                    {
                        tag: "presentation",
                        type: "section",
                        title: "help_page.health.text14",
                        items: [
                            { type: "text", value: "help_page.health.text15" },
                            { type: "text", value: "help_page.health.text16" },
                            { type: "text", value: "help_page.health.text17" },
                        ],
                    },
                    {
                        tag: "columns",
                        type: "section",
                        title: "help_page.health.text18",
                        items: [
                            { type: "text", value: "help_page.health.text19" },
                            { type: "img", value: "i4" },
                            { type: "text", value: "help_page.health.text20" },
                            { type: "img", value: "i5" },
                        ],
                    },
                    {
                        tag: "filters",
                        type: "section",
                        title: "help_page.health.text21",
                        items: [
                            { type: "text", value: "help_page.health.text22" },
                            { type: "img", value: "i6" },
                            { type: "text", value: "help_page.health.text23" },
                            { type: "img", value: "i7" },
                            { type: "text", value: "help_page.health.text24" },
                            { type: "img", value: "i8" },
                            { type: "text", value: "help_page.health.text25" },
                            { type: "img", value: "i9" },
                            { type: "text", value: "help_page.health.text26" },
                            { type: "img", value: "i10" },
                            { type: "text", value: "help_page.health.text27" },
                            { type: "img", value: "i11" },
                            { type: "text", value: "help_page.health.text28" },
                        ],
                    },
                ],
            },
            {
                tag: "claim-page",
                type: "section",
                title: "help_page.health.text29",
                items: [
                    { type: "text", value: "help_page.health.text30" },
                    { type: "text", value: "help_page.health.text31" },
                    { type: "img", value: "i12" },
                    { type: "text", value: "help_page.health.text32" },
                    { type: "img", value: "i13" },
                    { type: "text", value: "help_page.health.text33" },
                    { type: "img", value: "i14" },
                    { type: "text", value: "help_page.health.text34" },
                    { type: "img", value: "i15" },
                    { type: "text", value: "help_page.health.text35" },
                    { type: "img", value: "i16" },
                    { type: "text", value: "help_page.health.text36" },
                    { type: "img", value: "i17" },
                    { type: "text", value: "help_page.health.text37" },
                    { type: "img", value: "i18" },
                    { type: "text", value: "help_page.health.text38" },
                    { type: "img", value: "i19" },
                ],
            },
        ],
    },
    {
        tag: "frequent-questions",
        type: "section",
        title: "help_page.health.title2",
        items: [
            {
                tag: "forgot-password",
                type: "section",
                title: "help_page.health.text39",
                items: [
                    { type: "text", value: "help_page.health.text40" },
                    { type: "text", value: "help_page.health.text41" },
                    { type: "text", value: "help_page.health.text42" },
                    { type: "text", value: "help_page.health.text43" },
                    { type: "text", value: "help_page.health.text44" },
                ],
            },
            {
                tag: "no-claims",
                type: "section",
                title: "help_page.health.text45",
                items: [
                    { type: "text", value: "help_page.health.text46" },
                    { type: "img", value: "i20" },
                ],
            },
            {
                tag: "loading-forever",
                type: "section",
                title: "help_page.health.text47",
                items: [{ type: "text", value: "help_page.health.text48" }],
            },
        ],
    },
];

updateIds(helpElements);

export default helpElements;
