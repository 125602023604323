import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import MediaQuery from "react-responsive";
import bind from "memoize-bind";
import { Route as SupervisedRoute } from "@shift/telemetry-js";

import MEDIA_QUERIES from "@app/constants/media-queries";
import Spinner from "@generic/common/spinner";
import DocumentTitle from "@generic/common/document-title";
import Login from "@app/components/security/login";
import { loadLanguageUI } from "@app/languages";
import { HelpElements } from "@app/constants/help";

const ResetPassword = lazy(() => import("@app/components/security/reset-password"));
const HelpPage = lazy(() => import("@app/components/help/help-page"));

class SecurityWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
        };
    }

    async componentDidMount() {
        await loadLanguageUI(window.shiftContext.languages.default);
        this.setState({ isLoading: false });
    }

    renderForScreen(smallScreen) {
        return (
            <DocumentTitle>
                <div className={smallScreen ? "shift-small-screen" : ""}>
                    {this.state.isLoading && <Spinner />}
                    {!this.state.isLoading && (
                        <Suspense fallback={<Spinner />}>
                            <Switch>
                                <SupervisedRoute path="/resetPassword/:token/:user/:new?" component={ResetPassword} />
                                <Redirect exact from="/help" to="/help/user-guide" />
                                <Route
                                    path="/help/:chapter*"
                                    render={() => (
                                        <HelpPage
                                            language={window.shiftContext.languages.default}
                                            lob="p&c"
                                            helpElements={HelpElements}
                                        />
                                    )}
                                />

                                <Route exact render={() => <Login referrer={this.props.referrer} />} />
                            </Switch>
                        </Suspense>
                    )}
                </div>
            </DocumentTitle>
        );
    }

    render() {
        return <MediaQuery query={MEDIA_QUERIES.SMALL_SCREEN}>{bind(this.renderForScreen, this)}</MediaQuery>;
    }
}

SecurityWrapper.displayName = "SecurityWrapper";

export default SecurityWrapper;
